var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-start"},[_c('b',{staticClass:"text-left text-lg mx-2 p-2 text-blue"},[_vm._v("Paso "+_vm._s(_vm.current_step)+" de 2")])]),_c('Accordion',{key:"astep1",staticClass:"px-0",attrs:{"open":_vm.openAccordions.acc_1,"disable":false,"header":"1. De la función de atención y resolución de quejas"}},[_c('div',{staticClass:"w-full grid grid-cols-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"},[_c('Accordion',{key:"astep1.1",staticClass:"px-0 col-start-1 col-span-4",attrs:{"open":_vm.openAccordions.acc_11,"disable":false,"header":"1.1 Pronunciamientos del DCF a favor del consumidor financiero"}},[_c('BaseRadio',{ref:"ria_1_1_radio",staticClass:"col-span-2",attrs:{"name":"pronunciamiento_obligatorio_entidad","disabled":_vm.disbaledInput,"label":"¿Los pronunciamientos del DCF son obligatorios para la entidad?","radioOptions":_vm.options.oYesOrNot,"required":"true"},on:{"onChangeValueGetValue":function (value) { return _vm.onChangeRadio(value, 'ria_1_1_radio'); }},model:{value:(_vm.data_form.pronunciamiento_obligatorio_entidad),callback:function ($$v) {_vm.$set(_vm.data_form, "pronunciamiento_obligatorio_entidad", $$v)},expression:"data_form.pronunciamiento_obligatorio_entidad"}}),(
            _vm.errors &&
            _vm.errors.pronunciamiento_obligatorio_entidad &&
            !_vm.showSteps[0].isChecked
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),(
            (_vm.showSteps[0].showStep && _vm.showSteps[0].isChecked) ||
            (_vm.data_form && _vm.data_form.pronunciamiento_obligatorio_entidad == 1)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","disabled":_vm.disbaledInput,"label":"Describa brevemente cuál es la política de la entidad para tomar como obligatorias las decisiones de los defensores a favor de los consumidores.","placeholder":"Mensaje","maxlength":"1000"},model:{value:(_vm.data_form.descripcion_pronunciamiento_obligatorio_entidad),callback:function ($$v) {_vm.$set(_vm.data_form, "descripcion_pronunciamiento_obligatorio_entidad", $$v)},expression:"data_form.descripcion_pronunciamiento_obligatorio_entidad"}}):_vm._e(),(
            (!_vm.showSteps[0].showStep && _vm.showSteps[0].isChecked) ||
            (_vm.data_form && _vm.data_form.pronunciamiento_obligatorio_entidad == 2)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","disabled":_vm.disbaledInput,"label":"Describa brevemente cuál es la política de la entidad para NO tomar como obligatorias las decisiones de los defensores a favor de los consumidores.","placeholder":"Mensaje","maxlength":"1000"},model:{value:(_vm.data_form.descripcion_pronunciamiento_obligatorio_entidad),callback:function ($$v) {_vm.$set(_vm.data_form, "descripcion_pronunciamiento_obligatorio_entidad", $$v)},expression:"data_form.descripcion_pronunciamiento_obligatorio_entidad"}}):_vm._e(),(
            _vm.errors &&
            _vm.errors.descripcion_pronunciamiento_obligatorio_entidad &&
            _vm.showSteps[0].isChecked != null &&
            _vm.data_form.descripcion_pronunciamiento_obligatorio_entidad === ''
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),(_vm.showSteps[0].isChecked)?_c('h2',{staticClass:"mt-5 text-lg font-semibold text-left"},[_vm._v(" Cargue documento que contenga la política, reglamento o lineamientos establecidos por la entidad ")]):_vm._e(),(
            _vm.showSteps[0].isChecked ||
            (_vm.data_form &&
              _vm.data_form.pronunciamiento_obligatorio_entidad == 1) ||
            _vm.data_form.pronunciamiento_obligatorio_entidad == 2
          )?_c('manage_files',{staticClass:"mt-4",attrs:{"hideDragDrop":_vm.view === 'detail' ? true : false,"filesList":_vm.data_form.attachments,"dragDropTitle":'Límite nro. de archivos de carga: Máximo 5 archivos.\n Límite de peso total por carga: 5MB total de archivos subidos (Máximo 1MB por archivo cargado)',"extensions":[
            '.pdf',
            '.jpg',
            '.jpeg',
            '.png',
            '.doc',
            '.docx',
            '.xls',
            '.xlsx' ],"accept":".pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"},on:{"files-changed":_vm.handleFilesChange},model:{value:(_vm.data_form.attachments),callback:function ($$v) {_vm.$set(_vm.data_form, "attachments", $$v)},expression:"data_form.attachments"}}):_vm._e()],1),_c('Accordion',{key:"astep1.2",staticClass:"px-0 col-start-1 col-span-4",attrs:{"open":_vm.openAccordions.acc_11,"disable":false,"header":"1.2 Pronunciamientos del DCF a favor del consumidor financiero"}},[_c('BaseRadio',{ref:"ria_1_2_radio",staticClass:"col-span-2",attrs:{"name":"ria_1_2_radio","label":"¿En el período reportado la EV informó al DCF las decisiones del defensor a favor del consumidor financiero que fueron aceptadas, indicando la cuantía, modo, tiempo, lugar y cumplimiento?","disabled":_vm.disbaledInput,"radioOptions":_vm.options.oYesOrNot,"required":"true"},on:{"onChangeValueGetValue":function (value) { return _vm.onChangeRadio(value, 'ria_1_2_radio'); }},model:{value:(_vm.data_form.periodo_cumplimientos),callback:function ($$v) {_vm.$set(_vm.data_form, "periodo_cumplimientos", $$v)},expression:"data_form.periodo_cumplimientos"}}),(
            _vm.errors && _vm.errors.periodo_cumplimientos && !_vm.showSteps[1].isChecked
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),(
            (_vm.showSteps[1].showStep && _vm.showSteps[1].isChecked) ||
            (_vm.data_form && _vm.data_form.periodo_cumplimientos == 1)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","label":"Describa de manera general los lineamientos establecidos por la entidad para informar al DCF sobre las decisiones a favor que fueron aceptadas por la entidad. (No relacione casos individuales).","disabled":_vm.disbaledInput,"placeholder":"Mensaje","maxlength":"500"},model:{value:(_vm.data_form.periodo_cumplimientos_razones),callback:function ($$v) {_vm.$set(_vm.data_form, "periodo_cumplimientos_razones", $$v)},expression:"data_form.periodo_cumplimientos_razones"}}):_vm._e(),(
            (!_vm.showSteps[1].showStep && _vm.showSteps[1].isChecked) ||
            (_vm.data_form && _vm.data_form.periodo_cumplimientos == 2)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","disabled":_vm.disbaledInput,"label":"Razones por las cuales la EV no informó al DCF, sobre las decisiones a favor que fueron aceptadas por la entidad.","placeholder":"Mensaje","maxlength":"2000"},model:{value:(_vm.data_form.periodo_cumplimientos_razones),callback:function ($$v) {_vm.$set(_vm.data_form, "periodo_cumplimientos_razones", $$v)},expression:"data_form.periodo_cumplimientos_razones"}}):_vm._e(),(
            _vm.errors &&
            _vm.errors.periodo_cumplimientos_razones &&
            _vm.showSteps[1].isChecked != null &&
            _vm.data_form.periodo_cumplimientos_razones.length === 0
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),_c('BaseRadio',{ref:"ria_1_2_2_radio",staticClass:"col-span-2",attrs:{"name":"ria_1_2_2_radio","label":"¿En el período reportado la EV informó al DCF las decisiones del defensor a favor del consumidor financiero que no fueron aceptadas por la entidad, indicando las razones por las cuales se apartó de las mismas?  (datos consolidados no individuales)","disabled":_vm.disbaledInput,"radioOptions":_vm.options.oYesOrNot,"required":"true"},on:{"onChangeValueGetValue":function (value) { return _vm.onChangeRadio(value, 'ria_1_2_2_radio'); }},model:{value:(_vm.data_form.periodo_razones),callback:function ($$v) {_vm.$set(_vm.data_form, "periodo_razones", $$v)},expression:"data_form.periodo_razones"}}),(_vm.errors && _vm.errors.periodo_razones && !_vm.showSteps[2].isChecked)?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),(
            (_vm.showSteps[2].showStep && _vm.showSteps[2].isChecked) ||
            (_vm.data_form && _vm.data_form.periodo_razones == 1)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","disabled":_vm.disbaledInput,"label":"Describa de manera general los lineamientos establecidos por la entidad para informar al DCF sobre las decisiones a favor que no fueron aceptadas por la entidad. (No relacione casos individuales)","placeholder":"Mensaje","maxlength":"500"},model:{value:(_vm.data_form.descripcion_periodo_razones),callback:function ($$v) {_vm.$set(_vm.data_form, "descripcion_periodo_razones", $$v)},expression:"data_form.descripcion_periodo_razones"}}):_vm._e(),(
            (!_vm.showSteps[2].showStep && _vm.showSteps[2].isChecked) ||
            (_vm.data_form && _vm.data_form.periodo_razones == 2)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","disabled":_vm.disbaledInput,"label":"Razones por las cuales la EV no informó al DCF, sobre las decisiones a favor que no fueron aceptadas por la entidad.","placeholder":"Mensaje","maxlength":"2000"},model:{value:(_vm.data_form.descripcion_periodo_razones),callback:function ($$v) {_vm.$set(_vm.data_form, "descripcion_periodo_razones", $$v)},expression:"data_form.descripcion_periodo_razones"}}):_vm._e(),(
            _vm.errors &&
            _vm.errors.descripcion_periodo_razones &&
            _vm.showSteps[2].isChecked != null &&
            _vm.data_form.descripcion_periodo_razones.length === 0
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),_c('h2',{staticClass:"mt-5 text-lg font-semibold text-left"},[_vm._v(" Pronunciamientos de interés general ")]),_c('BaseRadio',{ref:"ria_1_2_3_radio",staticClass:"col-span-2",attrs:{"name":"ria_1_2_3_radio","label":"¿La DCF puso en conocimiento de la entidad pronunciamientos de interés general que emitió?","disabled":_vm.disbaledInput,"radioOptions":_vm.options.oYesOrNot,"required":"true"},on:{"onChangeValueGetValue":function (value) { return _vm.onChangeRadio(value, 'ria_1_2_3_radio'); }},model:{value:(_vm.data_form.pronunciamiento_general),callback:function ($$v) {_vm.$set(_vm.data_form, "pronunciamiento_general", $$v)},expression:"data_form.pronunciamiento_general"}}),(
            _vm.errors &&
            _vm.errors.pronunciamiento_general &&
            !_vm.showSteps[3].isChecked
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e(),(
            (_vm.showSteps[3].showStep && _vm.showSteps[3].isChecked) ||
            (_vm.data_form && _vm.data_form.pronunciamiento_general == 1)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","label":"En caso afirmativo, describa la actividad desplegada por la entidad al respecto.","disabled":_vm.disbaledInput,"placeholder":"Mensaje","maxlength":"500"},model:{value:(_vm.data_form.descripcion_pronunciamiento_general),callback:function ($$v) {_vm.$set(_vm.data_form, "descripcion_pronunciamiento_general", $$v)},expression:"data_form.descripcion_pronunciamiento_general"}}):_vm._e(),(
            (!_vm.showSteps[3].showStep && _vm.showSteps[3].isChecked) ||
            (_vm.data_form && _vm.data_form.pronunciamiento_general == 2)
          )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe","disabled":_vm.disbaledInput,"label":"En caso negativo, justifique.","placeholder":"Mensaje","maxlength":"500"},model:{value:(_vm.data_form.descripcion_pronunciamiento_general),callback:function ($$v) {_vm.$set(_vm.data_form, "descripcion_pronunciamiento_general", $$v)},expression:"data_form.descripcion_pronunciamiento_general"}}):_vm._e(),(
            _vm.errors &&
            _vm.errors.descripcion_pronunciamiento_general &&
            _vm.showSteps[3].isChecked != null &&
            _vm.data_form.descripcion_pronunciamiento_general.length === 0
          )?_c('span',{staticClass:"validate-errors-inputs"},[_vm._v("Este campo es obligatorio")]):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }