export const handleConditionalInput = (array, key, value) => {
  // console.log(array, key, value);
  return array.map((step) => {
    // console.log(step);
    if (step.key === key && value.id === 1) {
      step.isChecked = true;
      step.showStep = true;
    } else if (step.key === key && value.id === 2) {
      // TODO: Ajustar / isChecked
      step.isChecked = true; // true
      step.showStep = false;
    }
  });
};
