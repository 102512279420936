<template>
  <div>
    <div class="flex justify-start">
      <b class="text-left text-lg mx-2 p-2 text-blue"
        >Paso {{ current_step }} de 2</b
      >
    </div>

    <!-- <pre>{{ JSON.stringify(fields, null, 4) }}</pre> -->

    <!--  **************************************  START STEP 1  *********************************************  -->
    <Accordion
      :open="openAccordions.acc_1"
      :disable="false"
      key="astep1"
      class="px-0"
      header="1. De la función de atención y resolución de quejas"
    >
      <div
        class="w-full grid grid-cols-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
      >
        <!--  **************************************  START STEP 1.1  *********************************************  -->
        <Accordion
          :open="openAccordions.acc_11"
          :disable="false"
          key="astep1.1"
          class="px-0 col-start-1 col-span-4"
          header="1.1 Pronunciamientos del DCF a favor del consumidor financiero"
        >
          <!-- <pre>{{ JSON.stringify(data_form, null, 4) }}</pre> -->

          <BaseRadio
            ref="ria_1_1_radio"
            name="pronunciamiento_obligatorio_entidad"
            v-model="data_form.pronunciamiento_obligatorio_entidad"
            :disabled="disbaledInput"
            label="¿Los pronunciamientos del DCF son obligatorios para la entidad?"
            @onChangeValueGetValue="
              (value) => onChangeRadio(value, 'ria_1_1_radio')
            "
            :radioOptions="options.oYesOrNot"
            required="true"
            class="col-span-2"
          />
          <span
            class="validate-errors-inputs"
            v-if="
              errors &&
              errors.pronunciamiento_obligatorio_entidad &&
              !showSteps[0].isChecked
            "
            >Este campo es obligatorio</span
          >

          <BaseTextarea
            v-if="
              (showSteps[0].showStep && showSteps[0].isChecked) ||
              (data_form && data_form.pronunciamiento_obligatorio_entidad == 1)
            "
            class="col-start-1 col-span-2"
            v-model="data_form.descripcion_pronunciamiento_obligatorio_entidad"
            ref="describe"
            id="describe"
            :disabled="disbaledInput"
            label="Describa brevemente cuál es la política de la entidad para tomar como obligatorias las decisiones de los defensores a favor de los consumidores."
            placeholder="Mensaje"
            maxlength="1000"
          />

          <BaseTextarea
            v-if="
              (!showSteps[0].showStep && showSteps[0].isChecked) ||
              (data_form && data_form.pronunciamiento_obligatorio_entidad == 2)
            "
            class="col-start-1 col-span-2"
            v-model="data_form.descripcion_pronunciamiento_obligatorio_entidad"
            ref="describe"
            id="describe"
            :disabled="disbaledInput"
            label="Describa brevemente cuál es la política de la entidad para NO tomar como obligatorias las decisiones de los defensores a favor de los consumidores."
            placeholder="Mensaje"
            maxlength="1000"
          />

          <!-- <span>{{
            JSON.stringify(showSteps[0].isChecked != null, null, 4)
          }}</span> -->

          <!-- showSteps[0].isChecked && -->
          <span
            class="validate-errors-inputs"
            v-if="
              errors &&
              errors.descripcion_pronunciamiento_obligatorio_entidad &&
              showSteps[0].isChecked != null &&
              data_form.descripcion_pronunciamiento_obligatorio_entidad === ''
            "
            >Este campo es obligatorio</span
          >
          <h2
            v-if="showSteps[0].isChecked"
            class="mt-5 text-lg font-semibold text-left"
          >
            Cargue documento que contenga la política, reglamento o lineamientos
            establecidos por la entidad
          </h2>
          <manage_files
            class="mt-4"
            :hideDragDrop="view === 'detail' ? true : false"
            :filesList="data_form.attachments"
            v-model="data_form.attachments"
            v-if="
              showSteps[0].isChecked ||
              (data_form &&
                data_form.pronunciamiento_obligatorio_entidad == 1) ||
              data_form.pronunciamiento_obligatorio_entidad == 2
            "
            @files-changed="handleFilesChange"
            :dragDropTitle="'Límite nro. de archivos de carga: Máximo 5 archivos.\n Límite de peso total por carga: 5MB total de archivos subidos (Máximo 1MB por archivo cargado)'"
            :extensions="[
              '.pdf',
              '.jpg',
              '.jpeg',
              '.png',
              '.doc',
              '.docx',
              '.xls',
              '.xlsx',
            ]"
            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          >
          </manage_files>
        </Accordion>

        <!--  **************************************  START STEP 1.2  *********************************************  -->
        <Accordion
          :open="openAccordions.acc_11"
          :disable="false"
          key="astep1.2"
          class="px-0 col-start-1 col-span-4"
          header="1.2 Pronunciamientos del DCF a favor del consumidor financiero"
        >
          <BaseRadio
            ref="ria_1_2_radio"
            name="ria_1_2_radio"
            v-model="data_form.periodo_cumplimientos"
            label="¿En el período reportado la EV informó al DCF las decisiones del defensor a favor del consumidor financiero que fueron aceptadas, indicando la cuantía, modo, tiempo, lugar y cumplimiento?"
            :disabled="disbaledInput"
            @onChangeValueGetValue="
              (value) => onChangeRadio(value, 'ria_1_2_radio')
            "
            :radioOptions="options.oYesOrNot"
            required="true"
            class="col-span-2"
          />

          <span
            class="validate-errors-inputs"
            v-if="
              errors && errors.periodo_cumplimientos && !showSteps[1].isChecked
            "
            >Este campo es obligatorio</span
          >

          <BaseTextarea
            v-if="
              (showSteps[1].showStep && showSteps[1].isChecked) ||
              (data_form && data_form.periodo_cumplimientos == 1)
            "
            class="col-start-1 col-span-2"
            ref="describe"
            id="describe"
            v-model="data_form.periodo_cumplimientos_razones"
            label="Describa de manera general los lineamientos establecidos por la entidad para informar al DCF sobre las decisiones a favor que fueron aceptadas por la entidad. (No relacione casos individuales)."
            :disabled="disbaledInput"
            placeholder="Mensaje"
            maxlength="500"
          />

          <BaseTextarea
            v-if="
              (!showSteps[1].showStep && showSteps[1].isChecked) ||
              (data_form && data_form.periodo_cumplimientos == 2)
            "
            class="col-start-1 col-span-2"
            ref="describe"
            id="describe"
            v-model="data_form.periodo_cumplimientos_razones"
            :disabled="disbaledInput"
            label="Razones por las cuales la EV no informó al DCF, sobre las decisiones a favor que fueron aceptadas por la entidad."
            placeholder="Mensaje"
            maxlength="2000"
          />

          <span
            class="validate-errors-inputs"
            v-if="
              errors &&
              errors.periodo_cumplimientos_razones &&
              showSteps[1].isChecked != null &&
              data_form.periodo_cumplimientos_razones.length === 0
            "
            >Este campo es obligatorio</span
          >

          <BaseRadio
            ref="ria_1_2_2_radio"
            name="ria_1_2_2_radio"
            label="¿En el período reportado la EV informó al DCF las decisiones del defensor a favor del consumidor financiero que no fueron aceptadas por la entidad, indicando las razones por las cuales se apartó de las mismas?  (datos consolidados no individuales)"
            :disabled="disbaledInput"
            v-model="data_form.periodo_razones"
            @onChangeValueGetValue="
              (value) => onChangeRadio(value, 'ria_1_2_2_radio')
            "
            :radioOptions="options.oYesOrNot"
            required="true"
            class="col-span-2"
          />

          <span
            class="validate-errors-inputs"
            v-if="errors && errors.periodo_razones && !showSteps[2].isChecked"
            >Este campo es obligatorio</span
          >

          <BaseTextarea
            v-if="
              (showSteps[2].showStep && showSteps[2].isChecked) ||
              (data_form && data_form.periodo_razones == 1)
            "
            class="col-start-1 col-span-2"
            ref="describe"
            id="describe"
            v-model="data_form.descripcion_periodo_razones"
            :disabled="disbaledInput"
            label="Describa de manera general los lineamientos establecidos por la entidad para informar al DCF sobre las decisiones a favor que no fueron aceptadas por la entidad. (No relacione casos individuales)"
            placeholder="Mensaje"
            maxlength="500"
          />

          <BaseTextarea
            v-if="
              (!showSteps[2].showStep && showSteps[2].isChecked) ||
              (data_form && data_form.periodo_razones == 2)
            "
            class="col-start-1 col-span-2"
            ref="describe"
            id="describe"
            v-model="data_form.descripcion_periodo_razones"
            :disabled="disbaledInput"
            label="Razones por las cuales la EV no informó al DCF, sobre las decisiones a favor que no fueron aceptadas por la entidad."
            placeholder="Mensaje"
            maxlength="2000"
          />

          <span
            class="validate-errors-inputs"
            v-if="
              errors &&
              errors.descripcion_periodo_razones &&
              showSteps[2].isChecked != null &&
              data_form.descripcion_periodo_razones.length === 0
            "
            >Este campo es obligatorio</span
          >

          <h2 class="mt-5 text-lg font-semibold text-left">
            Pronunciamientos de interés general
          </h2>

          <BaseRadio
            ref="ria_1_2_3_radio"
            name="ria_1_2_3_radio"
            label="¿La DCF puso en conocimiento de la entidad pronunciamientos de interés general que emitió?"
            v-model="data_form.pronunciamiento_general"
            :disabled="disbaledInput"
            @onChangeValueGetValue="
              (value) => onChangeRadio(value, 'ria_1_2_3_radio')
            "
            :radioOptions="options.oYesOrNot"
            required="true"
            class="col-span-2"
          />

          <span
            class="validate-errors-inputs"
            v-if="
              errors &&
              errors.pronunciamiento_general &&
              !showSteps[3].isChecked
            "
            >Este campo es obligatorio</span
          >

          <BaseTextarea
            v-if="
              (showSteps[3].showStep && showSteps[3].isChecked) ||
              (data_form && data_form.pronunciamiento_general == 1)
            "
            class="col-start-1 col-span-2"
            ref="describe"
            id="describe"
            v-model="data_form.descripcion_pronunciamiento_general"
            label="En caso afirmativo, describa la actividad desplegada por la entidad al respecto."
            :disabled="disbaledInput"
            placeholder="Mensaje"
            maxlength="500"
          />

          <BaseTextarea
            v-if="
              (!showSteps[3].showStep && showSteps[3].isChecked) ||
              (data_form && data_form.pronunciamiento_general == 2)
            "
            class="col-start-1 col-span-2"
            v-model="data_form.descripcion_pronunciamiento_general"
            ref="describe"
            id="describe"
            :disabled="disbaledInput"
            label="En caso negativo, justifique."
            placeholder="Mensaje"
            maxlength="500"
          />

          <span
            class="validate-errors-inputs"
            v-if="
              errors &&
              errors.descripcion_pronunciamiento_general &&
              showSteps[3].isChecked != null &&
              data_form.descripcion_pronunciamiento_general.length === 0
            "
            >Este campo es obligatorio</span
          >

          <!-- showSteps[0].isChecked != null && -->
        </Accordion>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import { handleConditionalInput } from "../../../../../core/utils/handleStateInputs";
import manage_files from "@/feature/annual_report/presentation/views/components/manage_files.vue";

export default {
  components: {
    Accordion,
    manage_files,
  },
  props: [
    "current_step",
    "view",
    "errors",
    "handleFilesChange",
    "disbaledInput",
    "loadData",
    "data_form",
  ],
  data() {
    return {
      paramId: null,
      // fields: {},
      hideDragDrop: true,
      showSteps: [
        { showStep: false, isChecked: false, key: "ria_1_1_radio" },
        { showStep: false, isChecked: false, key: "ria_1_2_radio" },
        { showStep: false, isChecked: false, key: "ria_1_2_2_radio" },
        { showStep: false, isChecked: false, key: "ria_1_2_3_radio" },
      ],
      openAccordions: {
        acc_1: true,
        acc_11: false,
        acc_12: false,
        acc_13: true,
      },
      options: {
        oYesOrNot: [
          { id: 1, name: "Sí" },
          { id: 2, name: "No" },
        ],
      },
    };
  },
  methods: {
    onChangeRadio(value, key) {
      handleConditionalInput(this.showSteps, key, value);
    },

    nextStep(step) {
      this.current_step = step;
    },

    goToStepTwo(id) {
      this.$router.push(
        `/dashboard/register-annual-report/detail/step-2/${id}`
      );
    },
  },
  computed: {
    computedDataForm() {
      return this.data_form;
    },
  },
  async mounted() {
    this.data_form = await this.loadData();
    window.scrollTo(0, 0);
  },
};
</script>